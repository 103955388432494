import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Network/IP_Configuration/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import IPConfigTable from 'components/Web_User_Interface/1440p_Series/Network/IP_Configuration/ipConfigTable';
import IPConfigTableIPv6 from 'components/Web_User_Interface/1440p_Series/Network/IP_Configuration/ipConfigTableIPv6';
import CertCards from 'components/Web_User_Interface/1440p_Series/Network/IP_Configuration/CertCards';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // IP Configuration",
  "path": "/Web_User_Interface/1440p_Series/Network/IP_Configuration/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the Installation Wizard and don´t need to be changed. The camera´s http port might have to be changed to access your cameras webUI over the Internet.",
  "image": "./WebUI_1440p_SearchThumb_Network_IP_Configuration.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Network Menu // IP Configuration' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Change your cameras IP address, gateway and DNS server - all these settings are usually set up by the Installation Wizard and don´t need to be changed. The camera´s http port might have to be changed to access your cameras webUI over the Internet.' image='/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Network_IP_Configuration.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Netzwerk/IP_Konfiguration/' locationFR='/fr/Web_User_Interface/1440p_Series/Network/IP_Configuration/' crumbLabel="IP Configuration" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/90f73979bb645e57021c47a478fdfd88/d9199/1440p_WebUI_Network_IP_Configuration.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACH0lEQVQoz32SS08TURiG+RWyAAo7mHYKiradSzu3TgtlOp17Oy0aFpioO/ASLqLxH5iw0ZULChp/5WPmUG4JcfHm/XKS85zve78zJ8tVytIa1c0G/U8XeEd/2fp4RffDpVD78BLnYHor+2CKezjFP/5DfnaFYTnMzz9hYXGBlZUV5iqVCtLaKuvP6uQnvxh/uWR4ekH+ecr4rKinpCczzersdMqrb7+ZHP1A003WViXkyjrlssxcuVymkCxXCL0thlGfLPQYRh6h1xWK+l0iUXeuz3ZcgijFD2KMpoVpuNhWB6Wh3wGr1SpJmjGe7JJmQ1HHSUoUJ8IHQYjb6QqZlo3puHh+hOt0MA0bo+VQr6nXI98A4zhmNBoJj6Logfu+j2VZmKaJpmmoikLf6xH6Lk7xgNGmXr8HlGVZXMzznCRJHgXati2Auq6jaTrBoMdk5GAZpgA2Gur9DO+AWZb9F9hsNtH1JgO/R+xbAthxbFRlBpQk6RY4Ho8f6TDBHwQ0WwaqqqIrCkpDYRj0eRl0sYyWADbqysMMwzAkTVOSGehWYUDgdem5Jp2ui7nTQ9/eYjdN+LoX02nbtB33bstFhzdLKcYtoIULpQnZZI/8/XeGxz95t3fAeX/AeZDyJggZOAq1zQ021p9Sqcizjy1JostWqyUyKrwIvsiqqWvopkNrsI8WvWXb6bP//AWv6yrtWo35xRJLSyVKpRLLy8v8AwFOlzXvWJY6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90f73979bb645e57021c47a478fdfd88/e4706/1440p_WebUI_Network_IP_Configuration.avif 230w", "/en/static/90f73979bb645e57021c47a478fdfd88/d1af7/1440p_WebUI_Network_IP_Configuration.avif 460w", "/en/static/90f73979bb645e57021c47a478fdfd88/7f308/1440p_WebUI_Network_IP_Configuration.avif 920w", "/en/static/90f73979bb645e57021c47a478fdfd88/542bc/1440p_WebUI_Network_IP_Configuration.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/90f73979bb645e57021c47a478fdfd88/a0b58/1440p_WebUI_Network_IP_Configuration.webp 230w", "/en/static/90f73979bb645e57021c47a478fdfd88/bc10c/1440p_WebUI_Network_IP_Configuration.webp 460w", "/en/static/90f73979bb645e57021c47a478fdfd88/966d8/1440p_WebUI_Network_IP_Configuration.webp 920w", "/en/static/90f73979bb645e57021c47a478fdfd88/e46b2/1440p_WebUI_Network_IP_Configuration.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/90f73979bb645e57021c47a478fdfd88/81c8e/1440p_WebUI_Network_IP_Configuration.png 230w", "/en/static/90f73979bb645e57021c47a478fdfd88/08a84/1440p_WebUI_Network_IP_Configuration.png 460w", "/en/static/90f73979bb645e57021c47a478fdfd88/c0255/1440p_WebUI_Network_IP_Configuration.png 920w", "/en/static/90f73979bb645e57021c47a478fdfd88/d9199/1440p_WebUI_Network_IP_Configuration.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/90f73979bb645e57021c47a478fdfd88/c0255/1440p_WebUI_Network_IP_Configuration.png",
              "alt": "Web User Interface - 1440p Series - Network IP Configuration",
              "title": "Web User Interface - 1440p Series - Network IP Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can set whether the camera will be assigned an IP address by your router (DHCP) or, if you want to manually set a fixed address. Please check here on how to set a fixed IP address. In general, we recommend that you deactivate the automatic service and set a manual IP to avoid further trouble with the remote access to your camera.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IPConfigTable mdxType="IPConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c8ed249c185c3827bf0763233ba47ff/d9199/1440p_WebUI_Network_IP_Configuration_IPv6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACTklEQVQoz12S2U7bQBSGeYpWFRC4Ay8JLRXZNztOgHi343hJnEApt6gLqHR7C6ReVWopqOpTfpUnSEW9+HT+0Zn555wzs6aqZWRpl/J+DfPqlvH1Hw4v7xm9XzF4e4f25g79gUIb7+6wr3+TfLmn29d5+vQJ6xvrbG9vs6YoCtLuDpUXB2QfvjP7/Iv040+yT7eC6fVPog8FPwSTB51/vSe7+kaz1WN3R0JVKsiyyposyxSUVQV/OCA8HBIUjIxVFBiExyPCoxH+yMAbGbijAZZh0G3r9LoGWn9IvdZaGUqyTKVcYWIHxF7ExJ0wcUIioUOSMGGRLlhkS5JJiu8EBG6EZ4cMNINeV6Pb0akeNFYty8KwzNT2mYUxEyfANR3c8YrA8sRlgeUL7ZkOXpATBBmuqaFrRZUDqtVHhmVFJXFDXmVLFtmCOIqZRjFxlJBGKcs0Zz7NmEUZeTxjHufEoU861ej3+sKwVmv8m6GqKORhwvnyjMV8yTI/EXGe5czSOSf5Ca+XZyJ/lp9yOj8lCQI8s4fW6zPUNRr1B0NJkiirKoHp4lketukIrLGNeWwJirVrrfKu5WGZLqnvkzlD+r2OMKxV649aVss4xcGxLYz+xxw7gkIX+8ZHJnMv4MsyYDjQGOjG6pUV8cqSMHRNF9/x8WwPr4hCO7hhTnhxg3f5nfPkghttyI1hc35oYet1Dvb32Ks8R1HUh48tSaLKdrNNp9Wh1WiJeTTqzVVstGi2dZodg3ajg16tM6g3qb084NnGJpubJUqlEltbW/wFPYKbqtJ8xJMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c8ed249c185c3827bf0763233ba47ff/e4706/1440p_WebUI_Network_IP_Configuration_IPv6.avif 230w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/d1af7/1440p_WebUI_Network_IP_Configuration_IPv6.avif 460w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/7f308/1440p_WebUI_Network_IP_Configuration_IPv6.avif 920w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/542bc/1440p_WebUI_Network_IP_Configuration_IPv6.avif 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c8ed249c185c3827bf0763233ba47ff/a0b58/1440p_WebUI_Network_IP_Configuration_IPv6.webp 230w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/bc10c/1440p_WebUI_Network_IP_Configuration_IPv6.webp 460w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/966d8/1440p_WebUI_Network_IP_Configuration_IPv6.webp 920w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/e46b2/1440p_WebUI_Network_IP_Configuration_IPv6.webp 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c8ed249c185c3827bf0763233ba47ff/81c8e/1440p_WebUI_Network_IP_Configuration_IPv6.png 230w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/08a84/1440p_WebUI_Network_IP_Configuration_IPv6.png 460w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/c0255/1440p_WebUI_Network_IP_Configuration_IPv6.png 920w", "/en/static/9c8ed249c185c3827bf0763233ba47ff/d9199/1440p_WebUI_Network_IP_Configuration_IPv6.png 960w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c8ed249c185c3827bf0763233ba47ff/c0255/1440p_WebUI_Network_IP_Configuration_IPv6.png",
              "alt": "Web User Interface - 1440p Series - Network IP Configuration",
              "title": "Web User Interface - 1440p Series - Network IP Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <IPConfigTableIPv6 mdxType="IPConfigTableIPv6" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "ipv6-port-forwarding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ipv6-port-forwarding",
        "aria-label": "ipv6 port forwarding permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IPv6 Port Forwarding`}</h3>
    <ul>
      <li parentName="ul">{`see also:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Quick_Installation/Internet_Protocol_IPv6/"
            }}>{`What is IPv6 and how does SLAAC work?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Frequently_Asked_Question/WQHD_IPv6_Portforwarding/"
            }}>{`Instructions for IPv6 port activation in an AVM Fritzbox`}</a></li>
        </ul>
      </li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><img parentName="p" {...{
        "src": "./1440p_WebUI_Network_IP_Configuration_IPv6_AVM_01.png",
        "alt": "Web User Interface - 1440p Series - Network IP Configuration"
      }}></img></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The `}<strong parentName="p">{`global address`}</strong>{` is the address that must be used if you want to forward a port in your router to enable remote access. This address is used by your camera for your DDNS address and can be used to access the camera externally as soon as the HTTP or HTTPS port has been forwarded.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6a1076584e2dabd090752061dca15649/ae77d/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEUlEQVQ4y1WT2XajMBBEHYMWwCC8sArE6oUkc+Zh/v/bak63wXEe6kioobhdDbv9ZUZyqeGKCOLUw8tmRO03YvcHiftCVD8Q2zt0cYV3HuGdB3inHvtjh/3R4SNt8WEsvLTB3ljsgjCEDg/wdQShNKSSUGkBce7hHS7wpYQnFHwhIaSCz5Iv0ZlUCmLVTmmNMAwRBJoLXJQScRLDuQptW6Eoz8jzI4ri9FJOa3nC6Wz4fgIh7YTwobWE1gpS+JBSsKlJDW7LgsfngmmeYNsGlbWsunmKzrIiZ0oyZUJxOEGZHDK5wI8ziMhAMmGCZrrjer+j7XoUFZm0sK1DZRsWXV/y4tWZZMP4An0sodMSwhQQUQqlFZI0ZZquH1DWljVME8Z5RuMcmz1fVLERRceGSkkEWkFTBmvLVDTpEZVt+cHNkOmaBnXreG9dh6wo1gxXQh0EoElLpTmDrRAbg5oIhwG2JeMG4zSjG0b0w8ikRFxU9YuQtNOBZkOtf6b8JDQYvv7hvnxyjmTk+gGN6550reM9ZcgDWfUiJJN3dBrKdfnGY1lwfzwwX29ou46JNlEM5yx7mcntO9wC3Q7pOjEGdrhyW8M4wfX902jN88cw/z1lapVbDoJfLdOUS9usA3muNIRufqDpR7jbJ4blL7KygnrPcNu8U5I5/SkmTVlEGx0OiA4xojhBFK9rYhBGEd49/gONFDfUURHlOwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a1076584e2dabd090752061dca15649/e4706/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.avif 230w", "/en/static/6a1076584e2dabd090752061dca15649/d1af7/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.avif 460w", "/en/static/6a1076584e2dabd090752061dca15649/7f308/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.avif 920w", "/en/static/6a1076584e2dabd090752061dca15649/24329/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.avif 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6a1076584e2dabd090752061dca15649/a0b58/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.webp 230w", "/en/static/6a1076584e2dabd090752061dca15649/bc10c/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.webp 460w", "/en/static/6a1076584e2dabd090752061dca15649/966d8/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.webp 920w", "/en/static/6a1076584e2dabd090752061dca15649/e4604/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.webp 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a1076584e2dabd090752061dca15649/81c8e/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.png 230w", "/en/static/6a1076584e2dabd090752061dca15649/08a84/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.png 460w", "/en/static/6a1076584e2dabd090752061dca15649/c0255/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.png 920w", "/en/static/6a1076584e2dabd090752061dca15649/ae77d/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.png 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6a1076584e2dabd090752061dca15649/c0255/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_02.png",
              "alt": "Web User Interface - 1440p Series - Network IP Configuration",
              "title": "Web User Interface - 1440p Series - Network IP Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/96a7e7575c77f09ac288b86d8605b15f/2bef9/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6ElEQVQY021RSY7DMAzLJ6ZNvEq2vCT5//s4kNwAPfQgkBBoimE2lg5qE4EFykNKeO0H3oezefjri79/cRewR8a2hwzqF3jc2H1CqRVVGqgUHN6jisCFiExs/HAemQukdRAXM/s231R0XSfOc2LMiTaGYR8D0ruh7gx7t3l2j06HSzXjTa/1MVFEbGLOliikjEQEHyNiJnCtttOkmkx5qfLRJNO4kLClTJZkGbAlUCytoo21V/E8T3vc+kqn7677/lQTEIsgUl2G2pt2sztvP0UvBo6Lh2if8vdeXanmQT2mqN2HduOIhH/QlKPO7b0d/gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96a7e7575c77f09ac288b86d8605b15f/e4706/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.avif 230w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/d1af7/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.avif 460w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/7f308/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.avif 920w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/e3723/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/96a7e7575c77f09ac288b86d8605b15f/a0b58/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.webp 230w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/bc10c/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.webp 460w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/966d8/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.webp 920w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/a9a89/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96a7e7575c77f09ac288b86d8605b15f/81c8e/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.png 230w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/08a84/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.png 460w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/c0255/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.png 920w", "/en/static/96a7e7575c77f09ac288b86d8605b15f/2bef9/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/96a7e7575c77f09ac288b86d8605b15f/c0255/1440p_WebUI_Network_IP_Configuration_IPv6_AVM_03.png",
              "alt": "Web User Interface - 1440p Series - Network IP Configuration",
              "title": "Web User Interface - 1440p Series - Network IP Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`With IPv6, in contrast to IPv4, ports are only opened, not forwarded, so that every Instar 2K+ WQHD camera in the network can accessed via `}<strong parentName="p">{`the same port`}</strong>{`, but you have to use a separate DDNS address for each camera.`}</p>
    <p>{`For comparison, with IPv4 port forwarding, each camera is forwarded through a unique port. You can then access all cameras in the same network `}<strong parentName="p">{`via the same DDNS address`}</strong>{` by simply appending other ports to the address.`}</p>
    <h3 {...{
      "id": "web-access-encryption",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#web-access-encryption",
        "aria-label": "web access encryption permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web Access Encryption`}</h3>
    <p>{`To access your camera´s web user interface securely, use the HTTPS port you set above. Add this port to your camera´s `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Network/Remote_Access/"
      }}>{`DDNS Address`}</a>{` and add the `}<em parentName="p">{`https://`}</em>{` prefix, if you want to access the web user interface with your web browser - e.g. if your camera has the DDNS address abcd12.ddns-instar.de and your HTTPS port is 443, type `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://abcd12.ddns-instar.de:443"
        }}>{`https://abcd12.ddns-instar.de:443`}</a></strong>{` into your browsers address bar.`}</p>
    <p>{`Be aware that INSTAR Deutschland provided a valid encryption certificate for your DDNS address. If you try to access your camera´s local IP address over HTTPS - e.g. `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://192.168.1.20:443"
        }}>{`https://192.168.1.20:443`}</a></strong>{`, you will be confronted with a security warning. But there is no security risk in adding an exception to your browser, to access the site anyway:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <CertCards mdxType="CertCards" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      